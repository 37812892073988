import React from 'react';
import logo from './b58w37.jpg';
import './App.css';

function App() {
  return (
    <div>
      <body>
        <img src={logo} className="App-logo" alt="logo" />
      </body>
      <div className="Footer">
        email:  info@stayhungrynotthirsty.com
      </div>
    </div>
  );
}

export default App;
